// Dependencies
import { useEffect } from 'react'
import { navigate } from 'gatsby'

export default ({ location }) => {
  useEffect(() => {
    if (location.pathname.includes('en-gb')) {
      navigate('/en-gb/home')
      return
    }
    if (location.pathname.includes('pt-br')) {
      navigate('/pt-br/inicio')
      return
    }
    navigate('/pt-br/inicio')
  }, [])
  return null
}
